import { FC, useContext, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Button, Modal, Input, Badge, SelectMultipleInput } from 'ds/components'
import { Tag } from './Tag'

import { SessionContext } from 'lib/hoc/withSession'
import { tagQueryKeys, tagService } from 'lib/services/tagService'
import { Tag as TagModel } from 'lib/models/tag'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'

type Props = {
    selectedTagIds: number[]
    onChange: (tagIds: number[]) => void

    allowCreate?: boolean
    placeholder?: string
    label?: string
    className?: string
}

export const TagSelect: FC<Props> = ({
    selectedTagIds,
    onChange,
    allowCreate,
    placeholder,
    label,
    className,
}) => {
    const { metadata } = useContext(SessionContext)
    const queryClient = useQueryClient()

    const [newTagName, setNewTagName] = useState<string | undefined>(undefined)

    const { isPending, mutate } = useMutation<any, Error, string>({
        mutationFn: (name) => tagService.postTag({ name }),
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            toastSuccess: 'Tag creado',
            onSuccess: (data) => {
                if (data.tag_id) onChange([...selectedTagIds, data.tag_id])
                queryClient.invalidateQueries({
                    queryKey: tagQueryKeys.invalidateAll,
                })
                setNewTagName(undefined)
            },
        }),
    })

    const tags = useMemo(() => Object.values(metadata.tagsById), [metadata.tagsById])

    const selectedTags = useMemo(
        () =>
            selectedTagIds?.map((tagId) => metadata.tagsById[tagId]).filter(Boolean) ||
            [],

        [selectedTagIds, metadata.tagsById]
    )

    if (!tags.length && !allowCreate) return null

    return (
        <>
            <SelectMultipleInput
                className={className}
                label={label}
                maxWidth={allowCreate ? undefined : 80}
                items={tags}
                placeholder={placeholder || 'Selecciona tags'}
                selectedItems={selectedTags}
                setSelectedItems={(newTags: TagModel[]) =>
                    onChange(newTags.map((tag) => tag.tag_id))
                }
                getItemId={(tag) => tag.tag_id}
                itemToString={(tag) => tag.name}
                renderItem={(tag) => <Tag tag={tag} />}
                renderSelectedItem={(tag, onRemove) => (
                    <Badge bgColor={tag.color}>
                        {tag.name}
                        <span className="px-1 pointer" onClick={onRemove}>
                            &#10005;
                        </span>
                    </Badge>
                )}
                suggestedAction={
                    allowCreate ? (
                        <Button
                            fullWidth
                            size="sm"
                            variant="secondaryText"
                            type="button"
                            onClick={() => setNewTagName('')}
                        >
                            Crear tag
                        </Button>
                    ) : null
                }
            />
            {newTagName !== undefined && allowCreate ? (
                <Modal
                    size="sm"
                    onClose={() => setNewTagName(undefined)}
                    title="Crear tag"
                >
                    <Input
                        className="mb-4"
                        label="Nombre"
                        onChange={setNewTagName}
                        value={newTagName}
                    />
                    <Button
                        disabled={isPending || !newTagName.trim()}
                        onClick={() => {
                            if (!newTagName.trim()) return
                            mutate(newTagName.trim())
                        }}
                        type="button"
                    >
                        Crear
                    </Button>
                </Modal>
            ) : null}
        </>
    )
}
