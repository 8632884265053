import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { cfdiService } from 'lib/services/cfdiService'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { formatDateShort } from 'lib/utils/formatDateShort'
import { mutationOnSuccess } from 'lib/utils/mutationUtils'
import { checkIsValidUsoCfdi } from 'lib/utils/isValidUsoCfdi'
import { TextLink, Button } from 'ds/components'
import { isGlobalCFDI } from 'lib/constants/RFC'
import { InvoiceV2 } from 'lib/models/invoice'

type FormData = {
    payment_method: string
    use: string
    payment_form: string
    exchange: string
    send_email: boolean
}

type Props = {
    invoice: InvoiceV2
    formData: FormData
    getFormError: () => string | undefined
    setFormError: (error: string) => void
}

export const BulkCreateCfdiRow = ({
    invoice,
    formData,
    getFormError,
    setFormError,
}: Props) => {
    const queryClient = useQueryClient()
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null)

    const { isPending, mutate } = useMutation({
        mutationFn: cfdiService.postInvoiceCfdiIngreso,
        onError: () => {
            toast.error('Algo salió mal.')
            setFormError('Algo salió mal.')
            setIsSuccess(false)
        },
        onSuccess: mutationOnSuccess({
            onError: (error) => {
                toast.error(error)
                setFormError(error)
                setIsSuccess(false)
            },
            onSuccess: (data) => {
                setIsSuccess(true)
                toast.success(data.message)

                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                queryClient.invalidateQueries({ queryKey: ['cfdi'] })
            },
        }),
    })

    const customerTaxId = invoice.customer?.tax_id || null
    const isPublicRFC = isGlobalCFDI(customerTaxId)
    const customerTaxSystem = invoice.customer?.tax_system || null

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (getFormError() || isSuccess !== null) return false

        const isValidUsoCfdi = checkIsValidUsoCfdi({
            taxSystem: customerTaxSystem,
            cfdiUse: formData.use,
        })

        if (!isValidUsoCfdi) {
            toast.error('El uso de CFDI no es válido.')
            setFormError('El uso de CFDI no es válido.')
            return false
        }

        mutate({
            invoice_id: invoice.invoice_id,
            exchange: formData.exchange,

            payment_method: formData.payment_method,
            use: formData.use,
            payment_form: formData.payment_form,

            send_email: formData.send_email,
        })
    }

    const hasCfdi = !!invoice.cfdi_i_payment_method

    return (
        <div className="d-flex stacked-md align-items-center">
            <div className="inline-md">
                {isSuccess ? (
                    <span className="fs-24 success-500">
                        <ion-icon name="checkmark-circle" />
                    </span>
                ) : isSuccess === false ? (
                    <span className="fs-24 danger-500">
                        <ion-icon name="alert-circle-outline" />
                    </span>
                ) : (
                    <span className="fs-24 neutral-500">
                        <ion-icon name="ellipse-outline" />
                    </span>
                )}
            </div>
            <div className="flex-fill inline-md">
                <strong>
                    {invoice.customer_display_name} {' · '} Cobro {invoice.invoice_num}
                </strong>
                <div>
                    {formatCurrency(invoice.amount_total, 0, invoice.currency)}
                    {' · '} {formatDateShort(invoice.time_invoice)}
                    {hasCfdi ? (
                        <strong className="warning-500">
                            {' '}
                            · *Ya tiene factura valida.*
                        </strong>
                    ) : null}
                </div>
                {isPublicRFC && !hasCfdi ? (
                    <TextLink
                        newTab
                        href={`/invoices/${invoice.invoice_id}/cfdi`}
                        variant="secondary"
                    >
                        Ir al cobro a generar CFDI
                    </TextLink>
                ) : null}
            </div>
            <div>
                <Button
                    onClick={handleSubmit}
                    disabled={isSuccess || hasCfdi || !!isPublicRFC}
                    isLoading={isPending ? 'Generando...' : undefined}
                >
                    {formData.send_email ? 'Generar y enviar CFDI' : 'Generar CFDI'}
                </Button>
            </div>
        </div>
    )
}
