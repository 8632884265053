import { FC } from 'react'

import { Badge, Icon, Tooltip } from 'ds/components'

import { formatDateStringShort } from 'lib/utils/dateStringUtils'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { LatestNotification } from 'lib/models/notification'

type Props = {
    settledDate: string | null
    latestNotification?: LatestNotification
    amountPaid?: string
    amountRemaining?: string
    ecartLatestPaymentAttemptStatus?: string
}

export const NotificationBadge: FC<Props> = ({
    settledDate,
    amountRemaining,
    amountPaid,
    latestNotification,
    ecartLatestPaymentAttemptStatus,
}) => {
    if (settledDate)
        return (
            <Badge variant="success">Pagado {formatDateStringShort(settledDate)}</Badge>
        )
    if (ecartLatestPaymentAttemptStatus === 'declined')
        return <Badge variant="danger">Pago Fallido</Badge>
    if (Number(amountPaid))
        return (
            <Badge variant="warning">Por cobrar: {formatCurrency(amountRemaining)}</Badge>
        )

    if (latestNotification?.sending) {
        return (
            <Tooltip trigger={<Badge variant="primary">Enviando</Badge>}>
                {latestNotification.sending}
            </Tooltip>
        )
    }

    if (latestNotification?.sends) {
        return (
            <>
                <Tooltip trigger={<Badge variant="success">Enviado</Badge>}>
                    {latestNotification.sends}
                </Tooltip>

                {latestNotification.bounces || latestNotification.complaints ? (
                    <Tooltip
                        trigger={
                            <Badge variant="warning">
                                <Icon icon="ExclamationTriangle" />
                            </Badge>
                        }
                    >
                        {latestNotification.bounces ? (
                            <>
                                Rebotes: {latestNotification.bounces}
                                <br />
                            </>
                        ) : null}
                        {latestNotification.complaints ? (
                            <>Quejas: {latestNotification.complaints}</>
                        ) : null}
                    </Tooltip>
                ) : null}
            </>
        )
    }
    return <Badge variant="neutral">No enviado</Badge>
}
