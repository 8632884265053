import { FC } from 'react'

import { Tooltip, Icon, Badge } from 'ds/components'
import { InvoiceV2 } from 'lib/models/invoice'
import { PaymentPlanTableItem } from 'lib/models/paymentPlan'
import { PortalInvoice } from 'lib/models/portalInvoice'

type Props = {
    invoice?: InvoiceV2 | PortalInvoice
    paymentPlan?: PaymentPlanTableItem
}

export const CfdiBadge: FC<Props> = ({ invoice, paymentPlan }) => {
    const object = invoice || paymentPlan
    if (!object) return null

    const isInvoiceV2 = invoice && 'delete_on_cfdi_cancel' in invoice

    if (object.cfdi_i_payment_method)
        return (
            <>
                <Badge variant="success">Emitida {object.cfdi_i_payment_method}</Badge>
                {invoice && invoice.cfdi_p_cfdi_id ? (
                    <Badge variant="success">Pago</Badge>
                ) : null}
                {object.has_cfdi_cancellation_pending ? (
                    <Tooltip
                        trigger={
                            <Badge variant="warning">
                                <Icon icon="ExclamationTriangle" />
                            </Badge>
                        }
                    >
                        Cancelación pendiente
                        {isInvoiceV2 &&
                        invoice.delete_on_cfdi_cancel &&
                        !invoice.cfdi_p_cfdi_id
                            ? ' - Cobro sera borrado automáticamente si se cancela'
                            : null}
                    </Tooltip>
                ) : null}
            </>
        )
    if (object.has_cancelled_cfdi) return <Badge variant="danger">Cancelada</Badge>
    return <Badge variant="neutral">Sin factura</Badge>
}
