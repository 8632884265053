import { FC } from 'react'

type Props = {
    customer: string | { customer: string }
    orderDetail: string
}

export const CustomerAndOrderDetail: FC<Props> = ({ customer, orderDetail }) => {
    const customerText = typeof customer !== 'string' ? customer?.customer : customer
    return (
        <>
            <span>{customerText || ''}</span>
            {orderDetail ? <span className="neutral-500"> / {orderDetail}</span> : null}
        </>
    )
}
