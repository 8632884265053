import { ManualPagination } from 'ds/components/Table'
import { PaginationMetadata } from 'lib/models/paginationMetadata'

type BaseFilterObject = {
    offset: number
    limit: number
}

type Params = {
    filter: BaseFilterObject
    setFilter: (filter: Partial<BaseFilterObject>) => void
    paginationMetadata: PaginationMetadata
}

export const getManualPagination = ({
    paginationMetadata,
    filter,
    setFilter,
}: Params): ManualPagination => {
    return {
        offset: paginationMetadata.offset,
        limit: paginationMetadata.limit,
        result_count: paginationMetadata.result_count,
        total_count: paginationMetadata.total_count,
        onPrevPage: () => setFilter({ offset: filter.offset - filter.limit }),
        onNextPage: () => setFilter({ offset: filter.offset + filter.limit }),
    }
}
